import React, { useRef, useEffect } from 'react';
import PropTypes, { bool, func, number, string } from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import './TextBox.scss';

import Icon from '../Icon/Icon';

// Value in pixels for custom placement of asterisk depending on text.
// To avoid usage of 'canvas' measurement approach and redundant bindings we should provide custom method
const calcAsteriskLeft = (value) => {
  const avgCharWidthPx = 12;
  return value.length * avgCharWidthPx;
};

const clampValue = (value, min, max, step) => {
  if (value === '') return value;
  let clampedValue = parseFloat(value);

  if (min !== undefined && clampedValue < min) {
    clampedValue = min;
  }
  if (max !== undefined && clampedValue > max) {
    clampedValue = max;
  }

  // Limit decimals based on the step
  if (step !== undefined && step < 1) {
    const decimalPlaces = step.toString().split('.')[1]?.length || 0;
    clampedValue = parseFloat(clampedValue.toFixed(decimalPlaces));
  }

  return clampedValue;
};

const TextBox = ({
  type,
  max,
  min,
  step,
  iconId,
  value,
  placeholder,
  isHoverEnabled,
  isDisabled,
  shouldDisplayErrorMessages,
  showAsterisk,
  errorMessage,
  isDarkTheme,
  onChange,
  isFocused,
  onFocus,
  onBlur,
  onEnter,
  height,
}) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (isFocused) {
      textRef.current.focus();
    }
  }, [isFocused]);

  const handleChange = (event) => {
    const newValue = event.target?.value;

    if (type === 'number') {
      const clampedValue = clampValue(newValue, min, max, step);
      onChange(clampedValue);
    } else {
      onChange(event);
    }
  };

  return (
    <div
      className={classNames(
        'text-box-component',
        {
          disabled: isDisabled,
          'hover-enabled': !isDisabled && isHoverEnabled,
          invalid: shouldDisplayErrorMessages && errorMessage,
        },
        isDarkTheme ? 'dark' : 'light'
      )}
    >
      <div className="input-container">
        <input
          type={type}
          value={value}
          {...(type === 'number' && { max, min, step })}
          // preventing scrolling from increase or decrease number
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={(event) => (event.key === 'Enter' || event.keyCode === 13) && onEnter(event)}
          disabled={isDisabled}
          readOnly={isDisabled}
          ref={textRef}
          style={{ height }}
        />
        {iconId && <Icon className="input-icon" iconId={iconId}></Icon>}
        {showAsterisk && value && (
          <i className={classNames('icon-asterisk', 'fade-in')} style={{ left: calcAsteriskLeft(value) }}></i>
        )}
      </div>
      {shouldDisplayErrorMessages && errorMessage && (
        <div className="error-message" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

TextBox.propTypes = {
  type: string,
  max: number,
  min: number,
  step: number,
  iconId: string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: string,
  isHoverEnabled: bool,
  isDisabled: bool,
  shouldDisplayErrorMessages: bool,
  errorMessage: string,
  isDarkTheme: bool.isRequired,
  showAsterisk: bool,
  onChange: func.isRequired,
  isFocused: bool,
  onFocus: func,
  onBlur: func,
  onEnter: func,
  height: string,
};

TextBox.defaultProps = {
  type: 'text',
  isHoverEnabled: true,
  isDisabled: false,
  shouldDisplayErrorMessages: false,
  errorMessage: null,
  isDarkTheme: true,
  showAsterisk: false,
  onEnter: noop,
};

export default TextBox;
